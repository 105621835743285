/** @jsx jsx */
// import React from "react"
import { jsx } from "theme-ui";
// import { Link } from "gatsby";

import { graphql, StaticQuery } from "gatsby";

import Main from "../components/Main";
import SEO from "../components/SEO";
import Title from "../components/Title";
import Container from "../components/Container";
import SidebarRechtsgebiete from "../components/SidebarRechtsgebiete";

export default () => (
  <Main>
    <SEO title="Page two" />

    <Container sx={{ display: "flex" }}>
      <SidebarRechtsgebiete />

      <StaticQuery
        query={graphql`
          query GebieteQuery {
            allMarkdownRemark(
              filter: { frontmatter: { name: { eq: "rechtsgebiete" } } }
            ) {
              edges {
                node {
                  fields {
                    slug
                  }
                  frontmatter {
                    uberschrift
                    unteruberschrift
                    bild
                  }
                  rawMarkdownBody
                }
              }
            }
          }
        `}
        render={(data) => (
          <div>
            {data.allMarkdownRemark.edges.map((document) => (
              <div sx={{ mx: 3 }}>
                <Title
                  titleName={document.node.frontmatter.uberschrift}
                  titleSub={document.node.frontmatter.unteruberschrift}
                  titleLink=""
                  titleURL=""
                />
                <p>{document.node.rawMarkdownBody}</p>
              </div>
            ))}
          </div>
        )}
      />
    </Container>
  </Main>
);
